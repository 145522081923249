import React, { useState } from "react";
import { useParams } from "react-router-dom";
import "./ProductDetailPage.css";

// Example Product Image
import productImage from '../images/product.png';
import productImg from '../images/futuristicgift.png';
import giftBox from "../images/giftbox.png";
import Footer from "../components/Footer";

// Sample Product Data
const products = [
  {
    id: "propose-box",
    name: "Propose Box",
    category: "TRENDY",
    price: "₹25",
    originalPrice: "₹30",
    sku: "143",
    description: "A delightful gift for your loved one!",
    features: ["Natural ingredients", "Eco-friendly packaging", "Suitable for all skin types"],
    image: giftBox,
  },

  {
    id: "morning-wish",
    name: "Morning Wish",
    category: "TRENDY",
    price: "₹20",
    originalPrice: "₹30",
    sku: "424",
    description: "A delightful gift to start the day!",
    features: ["Natural ingredients", "Eco-friendly packaging", "Suitable for all skin types"],
    image: productImage,
  },

  {
    id: "miss-you-pack",
    name: "Miss You Pack",
    category: "Gift Box",
    price: "₹50",
    originalPrice: "₹60",
    sku: "278",
    description: "A delightful gift to start the day!",
    features: ["Natural ingredients", "Eco-friendly packaging", "Suitable for all skin types"],
    image: productImg,
  },
];

const ProductDetailsPage = () => {
  const { id } = useParams();
  const product = products.find((p) => p.id === id);

  const [quantity, setQuantity] = useState(1);

  if (!product) {
    return <h2>Product Not Found</h2>;
  }

  return (
    <><div className="product-details-container">
      {/* Breadcrumb */}
      <div className="breadcrumb">
        <a href="/">Home Page</a> &gt;{" "}
        <a href={`/category/${product.category}`}>{product.category}</a> &gt;{" "}
        {product.name}
      </div>

      {/* Product Details Section */}
      <div className="product-details">
        {/* Thumbnails */}
        <div className="thumbnails-column">
          <img src={product.image} alt={`${product.name} Thumbnail 1`} />
          <img src={product.image} alt={`${product.name} Thumbnail 2`} />
          <img src={product.image} alt={`${product.name} Thumbnail 3`} />
        </div>

        {/* Main Image */}
        <div className="main-image-container">
          <img src={product.image} alt={product.name} className="main-image" />
        </div>

        {/* Product Info */}
        <div className="product-info">
          <p className="product-category">{product.category}</p>
          <h1>{product.name}</h1>
          <p className="price">
            <span className="current-price">{product.price}</span>
            <span className="original-price">{product.originalPrice}</span>
          </p>
          <p><strong>SKU:</strong> {product.sku}</p>
          <p>{product.description}</p>

          {/* Quantity Selector */}
          <div className="quantity-section">
            <label htmlFor="quantity">Quantity:</label>
            <input
              type="number"
              id="quantity"
              min="1"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)} />
          </div>

          {/* Add to Cart */}
          <button className="add-to-cart">Add to Cart</button>
        </div>
      </div>

      {/* Features Section */}
      <div className="product-features">
        <h2>Explore the Features</h2>
        <ul>
          {product.features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
      </div>
    </div>
    <Footer />
    </>
  );
};

export default ProductDetailsPage;
