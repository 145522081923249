import React from 'react';
import { useParams } from 'react-router-dom';
import productImage from '../images/product.png'; // Adjust the path if necessary
import Footer from '../components/Footer';
import "./CategoryPage.css";

const allProducts = {
  gift: [
    { name: 'Birthday Surprise', price: '₹500', image: productImage },
    { name: 'Wedding Present', price: '₹1000', image: productImage },
  ],
  'digital-gift': [
    { name: 'eBook Gift Card', price: '₹200', image: productImage },
    { name: 'Online Course Access', price: '₹800', image: productImage },
  ],
  'on-sale': [
    { name: 'Discounted Gift Box', price: '₹300', image: productImage },
  ],
  featured: [
    { name: 'Top-Rated Moisturizer', price: '₹600', image: productImage },
  ],
};

const CategoryPage = () => {
  const { category } = useParams();
  const products = allProducts[category] || [];

  return (
    <>
      <div className="category-page">
        <h2>{category.replace('-', ' ').toUpperCase()}</h2>
        <div className="category-products">
          {products.length > 0 ? (
            products.map((product, index) => (
              <div key={index} className="product-card">
                <img src={product.image} alt={product.name} />
                <h3>{product.name}</h3>
                <p>{product.price}</p>
              </div>
            ))
          ) : (
            <p>No products available for this category.</p>

          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CategoryPage;
