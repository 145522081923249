import React from 'react';
import { Link } from 'react-router-dom';
import './ProductList.css';
import productImage from '../images/product.png';
import productImg from '../images/futuristicgift.png';
import giftBox from "../images/giftbox.png";
import Footer from './Footer';

const products = [
  
  { id: "propose-box", name: 'Propose Box', originalPrice: '₹30', price: '₹25', image: giftBox, category: 'TRENDY' },
  { id: "morning-wish", name: 'Morning Wish', originalPrice: '₹25', price: '₹20', image: productImage, category: 'TRENDY' },
  { id: "miss-you-pack", name: 'Miss You Pack', originalPrice: '₹60', price: '₹50', image: productImg, category: 'TRENDY' },
  // { id: "shaddi-proposal", name: 'Shaddi Proposal', originalPrice: '₹70', price: '₹59', image: productImg,category: 'TRENDY' },
];

const ProductList = () => (
  <>
    <div className="product-header">
      <i>~ The Products</i>
      <h2>Browse by Product</h2>
      <hr />
    </div>

    <div className="product-list">
      {products.map((product) => (

        <div key={product.id} className="product-item">
          
          <div className="product-box">
          <Link to={`/product/${product.id}`}>
            {/* <div className="product-badge">20% OFF</div> */}
            <img src={product.image} alt={product.name} />
            </Link>
          </div>
          {/* <p className="product-category">POPULAR ITEM</p> */}
          <p className="product-name">{product.name}</p>
          <p className="product-price">
            <span className="category-tag">{product.category}</span>  <span className='orginal-price'>{product.originalPrice}</span> {product.price}
          </p>
          
        </div>
      ))}
    </div>

  </>
);

export default ProductList;
